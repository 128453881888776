<template>
    <div class="app-body monthCard">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">优惠券列表</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" @click="editCoupon()">添加优惠券</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <!-- <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-input label="活动ID" v-model="pageParam.params.id" />
                <le-input label="活动名称" v-model="pageParam.params.activityTitle" />
                <le-input label="套餐ID" v-model="bindGoodsId" />
                <jd-datetime-range 
                    :large="true" 
                    label="活动时间" 
                    valueFormat="timestamp" 
                    :minDate.sync="pageParam.params.startTime" 
                    :maxDate.sync="pageParam.params.endTime" />
            </le-search-form> -->
            <le-pagview ref="couponPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.couponPagelist">
                <el-table ref="couponPagelist" class="couponPagelist" :data="tableData" :highlight-current-row="true"  style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column label="优惠券ID" prop="id" min-width="100"></el-table-column>
                    <el-table-column label="优惠券名称" prop="name" min-width="160"></el-table-column>
                    <el-table-column 
                        label="优惠券类型"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.couponType | initDic(couponTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="优惠券内容"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span v-if="row.couponType == 1">购买时打{{ row.discount }}折，满{{ util.numFormat(row.limitMoney) }}元可用</span>
                            <span v-if="row.couponType == 2">购买时抵扣{{ util.numFormat(row.discount) }}元，满{{ util.numFormat(row.limitMoney) }}元可用</span>
                            <span v-if="row.couponType == 3">订单金额不超过{{ util.numFormat(row.limitMoney) }}元可免单</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="有效期"
                        min-width="200">
                        <template slot-scope="{ row }">
                            <span v-if="row.timeType == 1">永久</span>
                            <span v-if="row.timeType == 2">领取后生效，有效期{{ row.day || '-' }}天</span>
                            <span v-if="row.timeType == 3">{{ row.startTime?row.startTime:'-' }} 至 {{ row.endTime?row.endTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="创建时间"
                        min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.createTime?row.createTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="创建人电话"
                        min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.mobile?row.mobile:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="80" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/edit.png" class="a-wh-16" @click="editCoupon(scope.row)"/>
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="使用人列表" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="toUsePage(scope.row)"/>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">筛选出</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal a-plr-10">条记录</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.couponPage,
                    method: "post",
                    params: {
                        name: '',//
                        id: '', //id
                    },
                    freshCtrl: 1,
                },
                couponTypeDic: []
            }
        },
        created () {
            this.$getDic('couponType').then(res=>{ this.couponTypeDic = res; })
            // this.$getDic('userJoinTimes').then(res=>{ this.userJoinTimesDic = res; })
            // this.$getDic('activityType').then(res=>{ this.activityTypeDic = res; })
            // this.$getDic('activityPeriod').then(res=>{ this.activityPeriodDic = res; })
            // this.$getDic('activityStatus').then(res=>{ this.statusStatusDic = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },  
        methods: {
            //获取列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    name: '',//
                    id: '', //id
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['couponPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },

            editCoupon (datas) {
                this.$router.push({
                    path: '/activity/coupon-edit',
                    query:{
                        id: datas&&datas.id?datas.id:''
                    }
                })
            },

            // 优惠券使用人列表
            toUsePage (datas) {
                this.$router.push({
                    path: '/activity/coupon-user-list',
                    query:{
                        id: datas&&datas.id?datas.id:''
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.couponPagelist{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
}

/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
</style>